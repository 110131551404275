import MyPrimary from 'images/PrimaryResidence.png';
import SecondHome from 'images/SecondHome.png';
import InvestProperty from 'images/InvestProperty.png';
import SingleFam from 'images/SingleFam.png';
import Condo from 'images/condo2.png';
import Townhome from 'images/Townhome2.png';
import MultiFam from 'images/MultiFam.png';

export const propertiesUse = {
  'My Primary Residence': MyPrimary,
  'A Second Home': SecondHome,
  'An Investment Property': InvestProperty
}

export const propertiesType = {
  'Single Family': SingleFam,
  'Condo': Condo,
  'Townhome': Townhome,
  'Multi Family': MultiFam
}

export const commonConstants = {
  GETTING_APP_INFO: 'GET_APP_INFO',
  GET_APP_INFO_SUCCESS: 'GET_APP_INFO_SUCCESS',
  GET_APP_INFO_FAILUE: 'GET_APP_INFO_FAILUE',
  TERMS_USE_PRIVACY_URL: '//docs.google.com/document/d/1izPnFVnVRzniG61Nxp9uTuBSSo2gN5Lj9KCRL4gV5JQ/edit?usp=sharing',
  SET_PROGRESS_BAR_INFO: 'SET_PROGRESS_BAR_INFO',
  SET_MAXIMUM_STEP: 'SET_MAXIMUM_STEP',
  NUMBER_OF_STEPS: 9,
  INIT_STEP: 1
}

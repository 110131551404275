import React, { Component } from 'react';
import './RefinanceType.scss';
import { connect } from 'react-redux';
import { history } from 'helpers/history';
import { Container, Col, Row } from 'react-bootstrap';
import { refinanceTypeActions } from 'actions/refinanceType';
import { questionnaireActions } from 'actions/questionnaire';
import { commonActions } from 'actions/common'

class RefinanceType extends Component {
  componentDidMount() {
    this.props.getRefinanceType();
  }

  goBack = () => {
    history.goBack();
  }

  chooseRefinanceType = (e, id) => {
    this.props.setRefinanceType(id);
    history.push('/zip-code');
  }

  render() {
    const { refinanceTypes } =  this.props;
    return(
      <Container className="refinance-type-page">
        <Row className="text-row">
          <Col xs={12}>
            <h3 className="color-blue question-title">
              What Type of Refinance do you need?
            </h3>
          </Col>
        </Row>

        <Row className="take-cash">
          <Col
            xs={12}
            md={{span: 6, offset: 3}}
            className="type-1"
            onClick={((e) => this.chooseRefinanceType(e, refinanceTypes[0].id))}
          >
            <span className="title">{refinanceTypes[0] ? refinanceTypes[0].name : ''}</span><br />
            <span className="good-for">-Good For-</span><br />
            <span className="item-text">Pay off high interest loans | Home Renovations</span><br />
          </Col>
        </Row>

        <Row className="lower-payment">
          <Col
            xs={12}
            md={{span: 6, offset: 3}}
            className="type-2"
            onClick={((e) => this.chooseRefinanceType(e, refinanceTypes[1].id))}
          >
            <span className="title">{refinanceTypes[1] ? refinanceTypes[1].name : ''}</span><br />
            <span className="good-for">-Good For-</span><br />
            <span className="item-text">Save on monthly payment</span>
          </Col>
        </Row>

        <Row className="shorten-loan">
          <Col
            xs={12}
            md={{span: 6, offset: 3}}
            className="type-3"
            onClick={((e) => this.chooseRefinanceType(e, refinanceTypes[2].id))}
          >
            <span className="title">{refinanceTypes[2] ? refinanceTypes[2].name : ''}</span><br />
            <span className="good-for">-Good For-</span><br />
            <span className="item-text">Get debt free faster | Shorten interest payment</span>
          </Col>
        </Row>
      </Container>
    )
  }
}

RefinanceType.defaultProps = {
  refinanceTypes: [
    {name: ''},
    {name: ''},
    {name: ''}
  ]
}

const mapStateToProps = (state) => {
  return {
    validZipcode: state.questionnaire.validZipcode,
    refinanceTypes: state.refinanceType.refinanceTypes,
    refinanceTypeId: state.questionnaire.refinanceTypeId,
    currentStep: state.currentStep
  }
}

const mapDispatchToProps = {
  getRefinanceType: refinanceTypeActions.getRefinanceType,
  setRefinanceType: questionnaireActions.setRefinanceType,
  setCurrentStep: commonActions.setCurrentStep
}

export default connect(mapStateToProps, mapDispatchToProps)(RefinanceType);

import React, { Component } from 'react';
import './Header.scss';
import { Container, Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import { commonConstants } from 'constants/common';
import { commonActions } from 'actions/common'
import { history } from 'helpers/history';
import _ from 'lodash';
import {
  CircularProgressbar,
  buildStyles
} from "react-circular-progressbar";
import 'react-circular-progressbar/dist/styles.css';
import AnimatedProgressProvider from "./AnimatedProgressProvider";
import { easeLinear } from "d3-ease";
import companyLogo from 'images/Folder Logo.png';

class Header extends Component {

  componentDidMount() {
    const initStep = commonConstants.INIT_STEP
    this.initDataForProgressBar(initStep)
    this.navigationListener(initStep)
  }

  initDataForProgressBar(initStep) {
    this.props.setCurrentMaxStep(commonConstants.NUMBER_OF_STEPS)
    this.props.setCurrentStep(initStep)
  }

  navigationListener(initStep) {
    history.listen((location, action) => {
      const currentStep = this.props.currentStep
      action === "PUSH" ? this.props.setCurrentStep(currentStep + 1) : this.props.setCurrentStep(currentStep > initStep ? currentStep - 1 : initStep)
    })
  }

  getProgressInfo() {
    if(!this.props.currentStep)
      return 0
    const maxStep = _.max([this.props.currentMaxStep, commonConstants.NUMBER_OF_STEPS])
    return _.floor((this.props.currentStep / maxStep) * 100);
  }

  renderProgressBar() {
    if(this.props.questionnaireClass.includes("hide-header"))
      return null
    return (
      <div className="progressbar-container d-flex justify-content-center my-3">
        <AnimatedProgressProvider
          valueStart={0}
          valueEnd={this.getProgressInfo()}
          duration={0.5}
          easingFunction={easeLinear}
        >
          {value => {
            const roundedValue = Math.round(value);
            return (
              <CircularProgressbar 
                value={value} 
                text={`${roundedValue}%`} 
                strokeWidth={10}
                styles={buildStyles({
                  textSize: "20px"
                })}
              />
            );
          }}
        </AnimatedProgressProvider>
      </div>
    )
  }

  render() {
    const appInfo = this.props.appInfo
    if(!appInfo && !this.props.questionnaireClass.includes("loan-options-bg"))
      return null
    return(
      <Container className="main-view">
        <div className={`company-logo-container text-center ${!this.props.isHomePage && !this.props.questionnaireClass.includes("loan-options-bg") ? 'd-none': ''}`}>
          <img src={companyLogo} alt="Fast phone service number" className="company-logo"/>
          <h6 className="mt-3">1st Nations Mortgage Corp.</h6>
        </div>
        {!this.props.questionnaireClass.includes("loan-options-bg") &&
          <Col className={`target-rate-board mainview-logo ${!this.props.isHomePage ? 'd-none': ''}`} style={{fontWeight: '800', color: '#3a3a3a'}}>
            <h2>
              Today's Target Rate
            </h2>
            <h1 className="color-blue" style={{fontWeight:'900', fontSize: '96px'}}>
              {appInfo.company.lowest_rate.toFixed(1)}%
            </h1>
            <h4 style={{fontWeight: '550', color: '#3a3a3a'}}>
              Find Out If You Qualify
            </h4>
          </Col>
        }
        {/* {this.renderProgressBar()} */}
        <Row className={`${this.props.questionnaireClass.includes("no-bg-page") ? '' : 'bg-div'} row col-md-6 ${this.props.questionnaireClass}`}></Row>
        { this.props.children }
      </Container>
    )
  }
}

const mapStateToProps = (state) => ({
  appInfo: state.commonInfo.appInfo,
  currentStep: state.currentStep,
  currentMaxStep: state.currentMaxStep
})

const mapDispatchToProps = {
  setCurrentStep: commonActions.setCurrentStep,
  setCurrentMaxStep: commonActions.setCurrentMaxStep
}

export default connect(mapStateToProps, mapDispatchToProps)(Header);

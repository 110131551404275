export const questionnaireConstants = {
  SUBMIT_ZIPCODE_SUCCESS: 'SUBMIT_ZIPCODE_SUCCESS',
  SUBMIT_ZIPCODE_FAILURE: 'SUBMIT_ZIPCODE_FAILURE',
  SET_REFINANCE_TYPE: 'SET_REFINANCE_TYPE',
  SET_PROPERTY_TYPE: 'SET_PROPERTY_TYPE',
  SET_PROPERTY_USE: 'SET_PROPERTY_USE',
  SET_PROPERTY_VALUE: 'SET_PROPERTY_VALUE',
  SET_FIRST_MORTGAGE_RATE: 'SET_FIRST_MORTGAGE_RATE',
  SET_FIRST_MORTGAGE_REMAINING: 'SET_FIRST_MORTGAGE_REMAINING',
  SET_ADDITIONAL_CASH: 'SET_ADDITIONAL_CASH',
  SET_AVERAGE_INCOME: 'SET_AVERAGE_INCOME',
  SUBMIT_MORTGAGE_OPTION_SUCCESS: 'SUBMIT_MORTGAGE_OPTION_SUCCESS',
  SUBMIT_MORTGAGE_OPTION_FAILURE: 'SUBMIT_MORTGAGE_OPTION_FAILURE',
  SET_ESTIMATE_YOUR_CREDIT: 'SET_ESTIMATE_YOUR_CREDIT',
  RESET_STATE: 'RESET_STATE',
  GET_LOAN_OPTIONS_SUCCESS: 'GET_LOAN_OPTIONS_SUCCESS',
  GET_LOAN_OPTIONS_FAILURE: 'GET_LOAN_OPTIONS_FAILURE',
  SET_PROSPECT_NAME: 'SET_PROSPECT_NAME',
  SET_PROSPECT_EMAIL: 'SET_PROSPECT_EMAIL',
};

export const questionnaireClass = {
  propertyUse: 'property-use-bg',
  propertyType: 'property-type-bg',
  propertyValue: 'property-value-bg',
  firstMortgageRate: 'first-mortgage-rate-bg',
  firstMortgageRemaining: 'first-mortgage-remaining-bg',
  additionalCash: 'additional-case-bg',
  averageIncome: 'average-income-bg',
  estimateCredit: 'estimate-credit-bg',
  mortgageResult: 'mortgage-result-bg',
  loanOptions: 'loan-options-bg'
}
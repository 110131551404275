import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import { history } from 'helpers/history';
import './Footer.scss';

class Footer extends Component {

  goBack = () => {
    history.goBack();
  }

  render() {
    return (
      <Row className="footer-section text-center mt-5">
        <Col xs={12} md={{ span: 6, offset: 3 }}>
          
        </Col>
      </Row>
    )
  }
}

const mapStateToProps = (state) => ({
})

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(Footer);

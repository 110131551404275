import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import './PropertyType.scss';
import { history } from 'helpers/history';
import { connect } from 'react-redux';
import { questionnaireActions } from 'actions/questionnaire';
import { commonActions } from 'actions/common'
import { propertiesType } from 'properties_map'

class PropertyType extends Component {
  choosePropertyType = (e, id) => {
    this.props.setPropertyType(id);
    history.push('/property-value');
  }

  renderPropertyType() {
    return (
      <Row className="mb-5">
        {Object.keys(propertiesType).map((key, index) => {
          return (
            <Col  xs={12} md={{span: 3}} onClick={((e) => {this.choosePropertyType(e, index + 1)})}>
              <div className="info-hover">
                <div className="icon-info">
                  <img src={propertiesType[key]} alt={key} className='property-icon'></img>
                </div>
                <h4 className="info-title">{key}</h4>
              </div>
            </Col>
          )
        })}
      </Row>
    )
  }

  render() {
    if (!this.props.refinanceTypeId) {
      history.push('/refinance-type');
    }

    return(
      <div className="property-type-page container">
        <Row className="text-row mb-3">
          <Col xs={12} md={{span: 6, offset: 3}}>
            <h3 className="color-blue question-title">
              What's the Property Type?
            </h3>
          </Col>
        </Row>
        {this.renderPropertyType()}
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    refinanceTypeId: state.questionnaire.refinanceTypeId,
    propertyTypeId: state.questionnaire.propertyTypeId,
    currentStep: state.currentStep
  }
}

const mapDispatchToProps = {
  setPropertyType: questionnaireActions.setPropertyType,
  setCurrentStep: commonActions.setCurrentStep
}

export default connect(mapStateToProps, mapDispatchToProps)(PropertyType);

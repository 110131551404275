import React, { Component } from 'react';
import './ZipCodePage.scss';
import { Container, Row, Col, Form } from 'react-bootstrap';
import { connect } from 'react-redux';
import { questionnaireActions } from 'actions/questionnaire';
import { commonConstants } from 'constants/common';
import { commonActions } from 'actions/common'
import { history } from 'helpers/history';

class ZipCodePage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      zipcode: ''
    }
  }

  goBack = () => {

    history.goBack();
  }

  changeZipCode = (event) => {
    this.setState({
      zipcode: event.target.value
    })
  }

  handleSubmit = (event) => {
    event.preventDefault();

    const zipcode = this.state.zipcode ? this.state.zipcode : this.props.zipcode;

    this.props.submitZipCode(zipcode);
  }

  render() {

    return(
      <Container className="zipcode-page">
        <Form onSubmit={this.handleSubmit}>
          <Row className="zipcode-text">
            <Col xs={12} md={{span: 6, offset: 3}}>
              <h3 className="color-blue question-title">
                Zip code of the property              
              </h3>
            </Col>
          </Row>

          <Row className="zipcode-input">
            <Col xs={12} md={{span: 6, offset: 3}}>
              <Form.Group controlId="zipcode" >
                <Form.Control
                  placeholder="Enter Zip"
                  type="text"
                  className="input"
                  onChange={this.changeZipCode}
                  required
                  defaultValue={this.props.zipcode ? this.props.zipcode : ''}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="zipcode-btn">
            <Col xs={12} md={{span: 6, offset: 3}} >
              <button className="next-btn" type="submit"><span>Next </span></button>
            </Col>
          </Row>
          <Row className='text-center terms-privacy'>
            <Col xs={12} md={{span: 6, offset: 3}} >
              <a href={commonConstants.TERMS_USE_PRIVACY_URL} target='_blank' rel="noopener noreferrer">Terms of Use and Privacy Policy</a>
            </Col>
          </Row>
        </Form>
      </Container>
    )
  }
}

ZipCodePage.defaultProps = {
  appInfo: {
    company: {
      lowest_rate: 0
    }
  }
}

const mapStateToProps = (state) => ({
  zipcode: state.questionnaire.zipcode,
  appInfo: state.commonInfo.appInfo,
  currentStep: state.currentStep
})

const mapDispatchToProps = {
  submitZipCode: questionnaireActions.submitZipCode,
  setCurrentStep: commonActions.setCurrentStep
}

export default connect(mapStateToProps, mapDispatchToProps)(ZipCodePage);

import React, { Component } from 'react';
import './NamePage.scss';
import { Container, Row, Col, Form } from 'react-bootstrap';
import { connect } from 'react-redux';
import { questionnaireActions } from 'actions/questionnaire';
import { commonActions } from 'actions/common'
import { history } from 'helpers/history';

class NamePage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      firstName: '',
      lastName: ''
    }
  }

  handleFirstName = (event) => {
    this.setState({
      firstName: event.target.value
    });
  }

  handleLastName = (event) => {
    this.setState({
      lastName: event.target.value
    });
  }

  handleSubmit = (event) => {
    event.preventDefault();

    const firstName = this.state.firstName ? this.state.firstName : this.props.firstName;
    const lastName = this.state.lastName ? this.state.lastName : this.props.lastName;
    this.props.setProspectName(firstName, lastName);
    history.push('/prospect-email');

  }

  render() {

    return(
      <Container className="name-page">
        <Form onSubmit={this.handleSubmit}>
          <Row className="name-text">
            <Col xs={12} md={{span: 6, offset: 3}}>
              <h3 className="color-blue question-title">
                What's your name?              
              </h3>
              <h4 className="color-light-blue">
                We keep your information Safe and Secure
              </h4>
            </Col>
          </Row>

          <Row className="name-input">
            <Col xs={12} md={{span: 6, offset: 3}}>
              <Form.Group controlId="firstName" >
                <Form.Control
                  placeholder="First Name"
                  type="text"
                  className="input"
                  onChange={this.handleFirstName}
                  required
                  defaultValue={this.props.firstName ? this.props.firstName : ''}
                />
              </Form.Group>
            </Col>
            <Col xs={12} md={{span: 6, offset: 3}}>
              <Form.Group controlId="firstName" >
                <Form.Control
                  placeholder="Last Name"
                  type="text"
                  className="input"
                  onChange={this.handleLastName}
                  required
                  defaultValue={this.props.lastName ? this
                    .props.lastName : ''}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="name-btn">
            <Col xs={12} md={{span: 6, offset: 3}} >
              <button className="next-btn" type="submit"><span>Next </span></button>
            </Col>
          </Row>

        </Form>
      </Container>
    )
  }
}

const mapStateToProps = (state) => ({
  firstName: state.questionnaire.firstName,
  lastName: state.questionnaire.lastName,
  currentStep: state.currentStep
})

const mapDispatchToProps = {
  setProspectName: questionnaireActions.setProspectName,
  setCurrentStep: commonActions.setCurrentStep
}

export default connect(mapStateToProps, mapDispatchToProps)(NamePage);

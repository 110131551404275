import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Container, Row, Col } from 'react-bootstrap';
import './PropertyUse.scss';
import { history } from 'helpers/history';
import { questionnaireActions } from 'actions/questionnaire';
import { commonActions } from 'actions/common'
import { commonConstants } from 'constants/common';
import { propertiesUse } from 'properties_map';

class PropertyUse extends Component {

  componentDidMount() {
    this.props.setCurrentStep(commonConstants.INIT_STEP)
  }

  choosePropertyUse = (e, id) => {
    this.props.setPropertyUse(id);
    history.push('/refinance-type');
  }

  renderPropertyUse() {
    return (
      <Row>
        {Object.keys(propertiesUse).map((key, index) => {
          return (
            <Col xs={12} md={{ span: 4}} onClick={((e) => { this.choosePropertyUse(e, index + 1) })}>
              <div className="info-hover">
                <div className="icon-info">
                  <img src={propertiesUse[key]} alt="my primary" className='property-icon'></img>
                </div>
                <h4 className="info-title">{key}</h4>
              </div>
            </Col>
          )
        })}
      </Row>
    )
  }

  render() {
    return (
      <Container className="property-use-page">
        <Row className="title-board">
          <h1 style={{ fontFamily: 'Oswald'}}className="title color-blue">Get a 60sec AI Refinance Quote</h1>
          {/* <img src={curveArrow} alt="arrow" className="curve-arrow" /> */}
        </Row>
        <Row>
          <Col className="ml-auto mr-auto" lg="12">
            <h3 className="color-blue question-title">
              What's the Property Use?
            </h3>
          </Col>
        </Row>
        {this.renderPropertyUse()}
      </Container>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    propertyUseId: state.questionnaire.propertyUseId,
    currentStep: state.currentStep
  }
}

const mapDispatchToProps = {
  setPropertyUse: questionnaireActions.setPropertyUse,
  setCurrentStep: commonActions.setCurrentStep
}

export default connect(mapStateToProps, mapDispatchToProps)(PropertyUse);

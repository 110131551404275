import { fetcher } from 'helpers/fetcher';
import { handleResponse } from './handleResponse';

const getRefinanceType = () => {
  const apiUrl = `${process.env.REACT_APP_API_URL}/questionnaires/get_refinance_type`;

  const requestOptions = {
    method: 'GET'
  }

  return fetcher(apiUrl, requestOptions)
    .then(handleResponse)
    .then(data => {
      return data;
    })
}

export const refinanceTypeService = {
  getRefinanceType
}

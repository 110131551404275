import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';

class OptionItem extends Component {
  render() {
    return (
      <div className={this.props.className}>
        <Col className="col text-center" xs={12}>
          <span className="text">{this.props.optionTitle}</span>
        </Col>

        <Col xs={12} className="title">
          <Row>
            <Col><span>Rate</span></Col>
            <Col><span>Payment</span></Col>
            <Col><span>Costs</span></Col>
            <Col><span>APR</span></Col>
          </Row>
        </Col>

        <Col xs={12} className="data">
          <Row>
            <Col><span className="text-data">{this.props.option.rate}%</span></Col>
            <Col><span className="text-data">${this.props.option.payment}</span></Col>
            <Col><span className="text-data">${this.props.option.cost}</span></Col>
            <Col><span className="text-data">{this.props.option.apr}%</span></Col>
          </Row>
        </Col>
      </div>
    )
  }
}

export default OptionItem;
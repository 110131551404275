import { questionnaireConstants } from 'constants/questionnaire';

const initialState = {};

export const questionnaire = (state = initialState, action) => {
  switch (action.type) {
    case questionnaireConstants.SUBMIT_ZIPCODE_SUCCESS:
      return {...state, validZipcode: true, zipcode: action.payload};
    case questionnaireConstants.SUBMIT_ZIPCODE_FAILURE:
      return {...state, validZipcode: false};
    case questionnaireConstants.SET_REFINANCE_TYPE:
      return {...state, refinanceTypeId: action.payload};
    case questionnaireConstants.SET_PROPERTY_TYPE:
      return {...state, propertyTypeId: action.payload};
    case questionnaireConstants.SET_PROPERTY_USE:
      return {...state, propertyUseId: action.payload};
    case questionnaireConstants.SET_PROPERTY_VALUE:
      return {
        ...state,
        propertyMinValue: action.payload.min,
        propertyMaxValue: action.payload.max
      };
    case questionnaireConstants.SET_FIRST_MORTGAGE_RATE:
      return {...state, firstMortgageRate: action.payload};
    case questionnaireConstants.SET_ESTIMATE_YOUR_CREDIT:
      return {
        ...state,
        minEstimateCreditScore: action.payload.minScore,
        maxEstimateCreditScore: action.payload.maxScore
      };
    case questionnaireConstants.SET_FIRST_MORTGAGE_REMAINING:
      return {
        ...state,
        firstMortgageMinRemaining: action.payload.minRemaining,
        firstMortgageMaxRemaining: action.payload.maxRemaining
      };
    case questionnaireConstants.SET_ADDITIONAL_CASH:
      return {...state, additionalCash: action.payload};
    case questionnaireConstants.SET_AVERAGE_INCOME:
      return {
        ...state,
        minMonthlyIncome: action.payload.minMonthlyIncome,
        maxMonthlyIncome: action.payload.maxMonthlyIncome
      }
    case questionnaireConstants.SUBMIT_MORTGAGE_OPTION_SUCCESS:
      return {...state};
    case questionnaireConstants.SUBMIT_MORTGAGE_OPTION_FAILURE:
      return {...state};
    case questionnaireConstants.GET_LOAN_OPTIONS_SUCCESS:
      return {
        ...state,
        prospect: {
          firstName: action.payload.data.user.first_name,
          lastName: action.payload.data.user.last_name
        },
        loanOptions: action.payload.data.loan_options
      };
    case questionnaireConstants.GET_LOAN_OPTIONS_FAILURE:
      return {...state};
    case questionnaireConstants.RESET_STATE:
      return initialState;
    case questionnaireConstants.SET_PROSPECT_NAME:
      return {
        ...state,
        firstName: action.payload.firstName,
        lastName: action.payload.lastName
      };
    case questionnaireConstants.SET_PROSPECT_EMAIL:
      return {
        ...state,
        email: action.payload.email,
      };
    default:
      return state;
  }
}

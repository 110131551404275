import React, { Component } from 'react';
import './AverageIncome.scss';
import 'rc-slider/assets/index.css';
import { Container, Row, Col } from 'react-bootstrap';
import Slider from 'rc-slider';
import { connect } from 'react-redux';
import { questionnaireActions } from 'actions/questionnaire';
import { history } from 'helpers/history';
import { commonActions } from 'actions/common'

const defaultMinIncome = 3000;
const defaultMaxIncome = 20000;
const defaultStep = 1000;

class AverageIncome extends Component {
  constructor(props) {
    super(props);

    this.state = {
      minIncome: 0,
      maxIncome: defaultMinIncome,
      editMode: false
    };
  }

  goBack = () => {
    history.goBack();
  }

  onSliderChange = (value) => {
    let minIncome = defaultMinIncome;
    let maxIncome = value;
    minIncome = maxIncome - defaultStep

    this.setState({
      minIncome,
      maxIncome,
      editMode: true
    });
  }

  submitAverageIncome = () => {
    this.props.setAverageIncome(this.state.minIncome, this.state.maxIncome);
    history.push('/estimate-your-credit');
  }

  numberWithCommas(number) {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  renderCurrentResult = (minScore, maxScore) => {
    if (maxScore <= defaultMinIncome || minScore === 0) {
      return(
        <span>&lt; ${this.numberWithCommas(defaultMinIncome)}</span>
      );
    }

    if (maxScore > defaultMaxIncome - defaultStep) {
      return(
        <span>&gt; ${this.numberWithCommas(defaultMaxIncome)}</span>
      );
    }

    return(
      <span>${this.numberWithCommas(minScore)} - ${this.numberWithCommas(maxScore)}</span>
    );
  }


  render() {
    return(
      <Container className="average-income-page">
        <Row className="text-row">
          <Col xs={12} md={{span: 8, offset: 2}}>
            <h3 className="color-blue question-title">What is your average monthly income?</h3>
          </Col>
        </Row>

        <Row className="range-row">
          <Col xs={12} md={{span: 6, offset: 3}}>
            {
              this.props.minMonthlyIncome && !this.state.editMode ?
              <span className="text">
                {this.renderCurrentResult(this.props.minMonthlyIncome, this.props.minMonthlyIncome)}
              </span>
            :
            <span className="text">
              {this.renderCurrentResult(this.state.minIncome, this.state.maxIncome)}
            </span>
            }
          </Col>
        </Row>

        <Row className="range-item">
          <Col xs={12} md={{span: 6, offset: 3}}>
            <Slider
              className='slider-custom'
              min={defaultMinIncome}
              max={defaultMaxIncome}
              step={defaultStep}
              onChange={this.onSliderChange}
              defaultValue={this.props.minMonthlyIncome ? this.props.minMonthlyIncome : defaultMinIncome}
            />
          </Col>

          <Col xs={6} md={{span: 3, offset: 3}} className="min">
            <span>&lt;3k</span>
          </Col>

          <Col xs={6} md={3} className="max">
            <span>&lt;20k</span>
          </Col>
        </Row>

        <Row className="btn-row">
          <Col xs={12} md={{span: 6, offset: 3}}>
            <button className="next-btn" onClick={this.submitAverageIncome}><span>Next </span></button>
          </Col>
        </Row>
      </Container>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    propertyTypeId: state.questionnaire.propertyTypeId,
    propertyMinValue: state.questionnaire.propertyMinValue,
    propertyMaxValue: state.questionnaire.propertyMaxValue,
    firstMortgageRate: state.questionnaire.firstMortgageRate,
    firstMortgageMinRemaining: state.questionnaire.firstMortgageMinRemaining,
    firstMortgageMaxRemaining: state.questionnaire.firstMortgageMaxRemaining,
    additionalCash: state.questionnaire.additionalCash,
    minMonthlyIncome: state.questionnaire.minMonthlyIncome,
    maxMonthlyIncome: state.questionnaire.maxMonthlyIncome,
    currentStep: state.currentStep
  }
}

const mapDispatchToProps = {
  setAverageIncome: questionnaireActions.setAverageIncome,
  setCurrentStep: commonActions.setCurrentStep
}

export default connect(mapStateToProps, mapDispatchToProps)(AverageIncome);

import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Header from 'components/Header/Header';
import { Route } from 'react-router-dom';
import { commonActions } from 'actions/common';
import Footer from '../Footer/Footer';

class QuestionLayout extends Component {
  componentDidMount() {
    this.props.getAppInfo(this.updateFavicon)
  }

  updateFavicon(url){
    document.getElementById('favicon').href = `//${url}`
    document.getElementById('apple-touch-icon').href = `//${url}`
  }

  render() {
    const {component: Component}  = this.props;
    const  { questionnaireClass, isHomePage } = this.props;

    return (
      <Route render={matchProps => (
        <Header questionnaireClass={questionnaireClass} isHomePage={isHomePage}>
          <Component {...matchProps} />
          {!questionnaireClass.includes("hide-footer") && <Footer />}
        </Header>
      )} />
    )
  }
}

QuestionLayout.propTypes = {
  questionnaireClass: PropTypes.string
}

QuestionLayout.defaultProps = {
  questionnaireClass: ''
}

const mapStateToProps = (state) => {
  return {
    appInfo: state.commonInfo.appInfo
  }
}

const mapDispatchToProps = {
  getAppInfo: commonActions.getAppInfo
}

export default connect(mapStateToProps, mapDispatchToProps)(QuestionLayout);

import { combineReducers } from 'redux';
import { questionnaire } from 'reducers/questionnaire';
import { refinanceType } from 'reducers/refinanceType';
import  { commonInfo, currentStep, currentMaxStep } from 'reducers/common';
export const structure = combineReducers({
  questionnaire,
  refinanceType,
  commonInfo,
  currentStep,
  currentMaxStep
});

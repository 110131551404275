import React, { Component } from 'react';
import './AdditionalCash.scss';
import 'rc-slider/assets/index.css';
import { Container, Row, Col } from 'react-bootstrap';
import Slider from 'rc-slider';
import { connect } from 'react-redux';
import { questionnaireActions } from 'actions/questionnaire';
import { history } from 'helpers/history';

class AdditionalCash extends Component {
  constructor(props) {
    super(props);

    this.state = {
      cash: 0,
      editMode: false
    };
  }

  goBack = () => {
    history.goBack();
  }

  onSliderChange = (value) => {
    let cash = value * 2000;

    this.setState({
      cash,
      editMode: true
    });
  }

  submitAdditionalCash = () => {
    this.props.setAdditionalCash(this.state.cash);
    history.push('/average-income');
  }

  numberWithCommas(number) {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  render() {
    return(
      <Container className="additional-cash-page">
        <Row className="text-row">
          <Col xs={12} md={{span: 8, offset: 2}}>
            <h3 className="color-blue question-title">
              How Much Cash Out would You Like?            
            </h3>
          </Col>
        </Row>

        <Row className="range-row">
          <Col xs={12} md={{span: 6, offset: 3}}>
            {
              this.props.additionalCash && !this.state.editMode ?
              <span className="text">
                ${this.numberWithCommas(this.props.additionalCash)} {this.props.additionalCash === 0 ? <span> (No Cash)</span> : null}
              </span>
              :
              <span className="text">
                ${this.numberWithCommas(this.state.cash)} {this.state.cash === 0 ? <span> (No Cash)</span> : null}
              </span>
            }
          </Col>
        </Row>

        <Row className="range-item">
          <Col xs={12} md={{span: 6, offset: 3}}>
            <Slider
              className='slider-custom'
              min={0}
              max={100}
              onChange={this.onSliderChange}
              defaultValue={this.props.additionalCash ? this.props.additionalCash/2000 : 0}
            />
          </Col>

          <Col xs={6} md={{span: 3, offset: 3}} className="min">
            <span>$0</span>
          </Col>

          <Col xs={6} md={3} className="max">
            <span>$200K</span>
          </Col>
        </Row>

        <Row className="btn-row">
          <Col xs={12} md={{span: 6, offset: 3}}>
            <button className="next-btn" onClick={this.submitAdditionalCash}><span>Next </span></button>

          </Col>
        </Row>
      </Container>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    propertyTypeId: state.questionnaire.propertyTypeId,
    propertyMinValue: state.questionnaire.propertyMinValue,
    propertyMaxValue: state.questionnaire.propertyMaxValue,
    firstMortgageRate: state.questionnaire.firstMortgageRate,
    firstMortgageMinRemaining: state.questionnaire.firstMortgageMinRemaining,
    firstMortgageMaxRemaining: state.questionnaire.firstMortgageMaxRemaining,
    additionalCash: state.questionnaire.additionalCash
  }
}

const mapDispatchToProps = {
  setAdditionalCash: questionnaireActions.setAdditionalCash
}

export default connect(mapStateToProps, mapDispatchToProps)(AdditionalCash);

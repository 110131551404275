import React from 'react';
// import { Router } from 'react-router';
import { Router, Switch, Route } from 'react-router-dom';
import { history } from 'helpers/history';
import { questionnaireClass } from 'constants/questionnaire';
import ZipCodePage from 'components/ZipCodePage/ZipCodePage';
import QuestionLayout from 'components/Layout/QuestionLayout';
import SorryPage from 'components/SorryPage/SorryPage';
import RefinanceType from 'components/RefinanceType/RefinanceType';
import PropertyType from 'components/PropertyType/PropertyType';
import PropertyValue from 'components/PropertyValue/PropertyValue';
import FirstMortgageRate from 'components/FirstMortgageRate/FirstMortgageRate';
import FirstMortgageRemaining from 'components/FirstMortgageRemaining/FirstMortgageRemaining';
import AdditionalCash from 'components/AdditionalCash/AdditionalCash';
import AverageIncome from 'components/AverageIncome/AverageIncome';
import MortgageResult from 'components/MortgageResult/MortgageResult';
import SubmitSuccess from 'components/SubmitSuccess/SubmitSuccess';
import PropertyUse from 'components/PropertyUse/PropertyUse';
import EstimateYourCredit from 'components/EstimateYourCredit/EstimateYourCredit';
import LoanOptions from 'components/LoanOptions/LoanOptions';
import GA from 'utils/GoogleAnalytics'
import NamePage from './components/NamePage/NamePage';
import EmailPage from './components/EmailPage/EmailPage';

export const Routes = () => {
  return (
    <Router history={history}>
      { GA.init() && <GA.RouteTracker /> }
      <Switch>
        <QuestionLayout exact path='/' component={PropertyUse} questionnaireClass='no-bg-page hide-footer'  isHomePage={true}/>

        <Route path="/sorry-page">
          <SorryPage />
        </Route>

        <QuestionLayout exact path="/refinance-type" component={RefinanceType} questionnaireClass='no-bg-page'/>

        <QuestionLayout exact path='/zip-code' component={ZipCodePage} questionnaireClass='no-bg-page'/>

        <QuestionLayout exact path='/property-type' component={PropertyType} questionnaireClass={questionnaireClass.propertyType}/>

        <QuestionLayout exact path='/property-value' component={PropertyValue} questionnaireClass={questionnaireClass.propertyValue} />

        <QuestionLayout exact path='/first-mortgage-rate' component={FirstMortgageRate} questionnaireClass={questionnaireClass.firstMortgageRate} />

        <QuestionLayout exact path='/first-mortgage-remaining' component={FirstMortgageRemaining} questionnaireClass={questionnaireClass.firstMortgageRemaining} />

        <QuestionLayout exact path='/additional-cash' component={AdditionalCash} questionnaireClass={questionnaireClass.additionalCash} />

        <QuestionLayout exact path='/average-income' component={AverageIncome} questionnaireClass={questionnaireClass.averageIncome} />

        <QuestionLayout exact path='/estimate-your-credit' component={EstimateYourCredit} questionnaireClass={questionnaireClass.estimateCredit} />

        <QuestionLayout exact path='/mortgage-result' component={MortgageResult} questionnaireClass={questionnaireClass.mortgageResult} />

        <QuestionLayout exact path='/submit-success' component={SubmitSuccess} questionnaireClass='success-page-bg hide-header hide-footer'/>

        <QuestionLayout exact path='/prospect-name' component={NamePage} questionnaireClass='no-bg-page'/>

        <QuestionLayout exact path='/prospect-email' component={EmailPage} questionnaireClass='no-bg-page'/>

        {/* <QuestionLayout path="/loan-options/:id" component={LoanOptions} /> */}

        <Route exact path="/loan-options/:id" component={LoanOptions}/>
      </Switch>
    </Router>
  )
}

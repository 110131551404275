import { refinanceTypeService } from 'services/refinanceTypeService';
import { refinanceTypeConstants } from 'constants/refinanceType';

const getRefinanceType = () => {
  const success = data => {
    return {
      type: refinanceTypeConstants.GET_REFINANCE_TYPE_SUCCESS,
      payload: data.refinance_type
    };
  };

  const failure = error => {
    return {
      type: refinanceTypeConstants.GET_REFINANCE_TYPE_FAILURE,
      payload: error
    };
  };

  return dispatch => {
    refinanceTypeService.getRefinanceType().then(
      data => {
        dispatch(success(data));
      },
      error => {
        dispatch(failure(error));
      }
    );
  };
}

export const refinanceTypeActions = {
  getRefinanceType
};

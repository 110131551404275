import { refinanceTypeConstants } from 'constants/refinanceType';

const initialState = {};

export const refinanceType = (state = initialState, action) => {
  switch (action.type) {
    case refinanceTypeConstants.GET_REFINANCE_TYPE_SUCCESS:
      return {...state, refinanceTypes: action.payload};
    case refinanceTypeConstants.GET_REFINANCE_TYPE_FAILURE:
      return {...state};
    default:
      return state;
  }
}

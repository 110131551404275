import thunk from 'redux-thunk';
import { createLogger } from 'redux-logger';
import { structure } from './structure';
import {
  createStore,
  applyMiddleware
} from 'redux';

const middleware = applyMiddleware(thunk, createLogger());

export const store = createStore(structure, middleware);

import React, { Component } from 'react';
import './EmailPage.scss';
import { Container, Row, Col, Form } from 'react-bootstrap';
import { connect } from 'react-redux';
import { questionnaireActions } from 'actions/questionnaire';
import { commonActions } from 'actions/common'
import { history } from 'helpers/history';

class EmailPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
    }
  }

  handleEmail = (event) => {
    this.setState({
      email: event.target.value
    });
  }

  handleSubmit = (event) => {
    event.preventDefault();

    const email = this.state.email ? this.state.email : this.props.email;

    this.props.setProspectEmail(email);
    history.push('/mortgage-result');

  }

  render() {

    return(
      <Container className="email-page">
        <Form onSubmit={this.handleSubmit}>
          <Row className="email-text">
            <Col xs={12} md={{span: 6, offset: 3}}>
              <h3 className="color-blue question-title">
                What's your Email?              
              </h3>
              <h4 className="color-light-blue">
                We don't send any spams. Seriously!
              </h4>
            </Col>
          </Row>

          <Row className="email-input">
            <Col xs={12} md={{span: 6, offset: 3}}>
              <Form.Group controlId="email" >
                <Form.Control
                  placeholder="Email"
                  type="email"
                  className="input"
                  onChange={this.handleEmail}
                  required
                  defaultValue={this.props.email ? this.props.email : ''}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="email-btn">
            <Col xs={12} md={{span: 6, offset: 3}} >
              <button className="next-btn" type="submit"><span>Next </span></button>
            </Col>
          </Row>

        </Form>
      </Container>
    )
  }
}

const mapStateToProps = (state) => ({
  email: state.questionnaire.email,
  currentStep: state.currentStep
})

const mapDispatchToProps = {
  setProspectEmail: questionnaireActions.setProspectEmail,
  setCurrentStep: commonActions.setCurrentStep
}

export default connect(mapStateToProps, mapDispatchToProps)(EmailPage);

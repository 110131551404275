import React, { Component } from 'react';
import './SubmitSuccess.scss';
import { history } from 'helpers/history';
import { Container, Col, Row } from 'react-bootstrap';
import {Helmet} from "react-helmet";

class SubmitSucess extends Component {
  handleClose = () => {
    history.push('/');
  }

  render() {
    return(
      <Container className="success-page">
        <Row className="success-text">
          <Col xs={12} md={{span: 6, offset: 3}}>
            <span>Your information submitted successfully, please check your text messages for results!</span>
          </Col>
          <Helmet>
            <script>{`{"gtag('event', 'conversion', {'send_to': 'AW-1018373724/CsVzCN_7jekBENzMzOUD'});"}`}</script>
          </Helmet>
        </Row>

        <Row className="close-btn">
          <Col xs={12} md={{span: 6, offset: 3}}>
            <button className="next-btn" onClick={this.handleClose} type="submit"><span>Next </span></button>
          </Col>
        </Row>
      </Container>
    )
  }
}

export default SubmitSucess;

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Container, Row, Col, Form } from 'react-bootstrap';
import './MortgageResult.scss';
import { questionnaireActions } from 'actions/questionnaire';
import { history } from 'helpers/history';
import { commonActions } from 'actions/common'

class MortgageResult extends Component {
  constructor(props) {
    super(props);

    this.state = {
      mobileNumber: '',
    }
  }

  componentDidMount() {
    const {
      zipcode
    } = this.props;

    if (!zipcode) {
      this.onReload()
    }
  }

  onReload = () => {
    history.push('/')
  }

  goBack = () => {
    history.goBack();
  }

  submitOption = (event) => {
    event.preventDefault();

    const {
      firstName,
      lastName,
      email,
      zipcode,
      refinanceTypeId,
      propertyTypeId,
      propertyMinValue,
      propertyMaxValue,
      propertyUseId,
      firstMortgageMinRemaining,
      firstMortgageMaxRemaining,
      additionalCash,
      minMonthlyIncome,
      maxMonthlyIncome,
      minEstimateCreditScore,
      maxEstimateCreditScore
    } = this.props;

    const {
      mobileNumber,
    } = this.state;

    const objSubmit = {
      user: {
        zip_code: zipcode,
        first_name: firstName,
        last_name: lastName,
        email: email,
        phone_number: mobileNumber,
      },

      mortgage: {
        refinance_id: refinanceTypeId,
        property_use_id: propertyUseId,
        property_type_id: propertyTypeId,
        min_property_value: propertyMinValue,
        max_property_value: propertyMaxValue,
        min_loan_amount: firstMortgageMinRemaining,
        max_loan_amount: firstMortgageMaxRemaining,
        additional_cash: additionalCash,
        min_monthly_income: minMonthlyIncome,
        max_monthly_income: maxMonthlyIncome,
        min_estimate_credit_score: minEstimateCreditScore,
        max_estimate_credit_score: maxEstimateCreditScore
      }

    }

    this.props.submitMortgageOption(objSubmit);
    history.push('/submit-success');
  }

  handleMobileNumber = (event) => {
    this.setState({
      mobileNumber: event.target.value
    });
  }

  handlePassword = (event) => {
    this.setState({
      password: event.target.value
    });
  }

  render() {
    return(
      <Container className="mortgage-result-page">
        <Row className="first-text">
          <Col xs={12} md={{span: 8, offset: 2}}>
            <h3 className="color-blue question-title">
              Your 3 Mortgage Options Are Ready to Go!
            </h3>
          </Col>
        </Row>

        <Row className="second-text">
          <Col xs={12} md={{span: 8, offset: 2}}>
            <h4 className="color-light-blue question-title">
            Provide your Mobile Number to Get Your Personalized Results Instantly Texted to You (email takes us longer)
            </h4>
          </Col>
        </Row>
        <Form onSubmit={this.submitOption}>
          <Row className="input-form">
            <Col xs={12} md={{span: 6, offset: 3}}>
              <Form.Group controlId="phonenumber" >
                <Form.Control
                  placeholder="Mobile Phone #"
                  required={true}
                  className="mb-2"
                  onChange={this.handleMobileNumber}/>
                <span className="note color-light-blue">Enter your Mobile Number for (3) Instant Rate Quotes.</span><br/>
              </Form.Group>
            </Col>

            <Col xs={12} md={{span: 4, offset: 4}}>
              <button className="next-btn" type="submit"><span>Send My Options </span></button>
            </Col>
          </Row>
        </Form>
      </Container>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    email: state.questionnaire.email,
    firstName: state.questionnaire.firstName,
    lastName: state.questionnaire.lastName,
    zipcode: state.questionnaire.zipcode,
    refinanceTypeId: state.questionnaire.refinanceTypeId,
    propertyTypeId: state.questionnaire.propertyTypeId,
    propertyMinValue: state.questionnaire.propertyMinValue,
    propertyMaxValue: state.questionnaire.propertyMaxValue,
    propertyUseId: state.questionnaire.propertyUseId,
    // firstMortgageRate: state.questionnaire.firstMortgageRate,
    firstMortgageMinRemaining: state.questionnaire.firstMortgageMinRemaining,
    firstMortgageMaxRemaining: state.questionnaire.firstMortgageMaxRemaining,
    additionalCash: state.questionnaire.additionalCash,
    minMonthlyIncome: state.questionnaire.minMonthlyIncome,
    maxMonthlyIncome: state.questionnaire.maxMonthlyIncome,
    minEstimateCreditScore: state.questionnaire.minEstimateCreditScore,
    maxEstimateCreditScore: state.questionnaire.maxEstimateCreditScore,
    currentStep: state.currentStep
  }
}

const mapDispatchToProps = {
  submitMortgageOption: questionnaireActions.submitMortgageOption,
  setCurrentStep: commonActions.setCurrentStep
}

export default connect(mapStateToProps, mapDispatchToProps)(MortgageResult);

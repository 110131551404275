import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { questionnaireActions } from 'actions/questionnaire';
import { commonActions } from 'actions/common';
import './LoanOptions.scss';
import Header from 'components/Header/Header';
import OptionItem from './OptionItem';

class LoanOptions extends Component {
  componentDidMount() {
    this.props.getLoanOptions(this.props.match.params.id)
    this.props.getAppInfo(this.updateFavicon)
  }

  updateFavicon(url){
    console.log(url);
    document.getElementById('favicon').href = `${url}`
    document.getElementById('apple-touch-icon').href = `${url}`
    document.getElementsByClassName('company-logo')[0].src = `${url}`
  }

  render() {
    const lowestCost = this.props.loanOptions.lowest_cost || {}
    const lowestRate = this.props.loanOptions.lowest_rate || {}
    const middleRate = this.props.loanOptions.middle_rate || {}
    const applyButton = this.props.loanOptions.apply_button_link

    return(
      <Header questionnaireClass='loan-options-bg hide-header'>
        <Container className="loan-options-page col-md-6 offset-md-3">
          <Row className="text-row">
            <Col xs={12}>
              <span>30yr Loan Options for {this.props.prospect.firstName} {this.props.prospect.lastName}</span>
            </Col>
          </Row>
          <OptionItem
            className='lowest-cost option-1'
            optionTitle='#1 Lowest Cost Refinance'
            option={lowestCost}
          ></OptionItem>
          <OptionItem
            className='lowest-cost option-1'
            optionTitle='#2 Some Cost Refinance'
            option={middleRate}
          ></OptionItem>
          <OptionItem
            className='lowest-cost option-1'
            optionTitle='#3 Lowest Rate Refinance'
            option={lowestRate}
          ></OptionItem>
          <Row className="submit">
            <Col xs={12}>
              <a href={applyButton} target='_blank' rel="noopener noreferrer">
                <Button className="btn">Apply Now</Button>
              </a>
              <br></br>
              <span className="text-note">Questions - Just Reply via text, email or call <a href="tel:7202207465">720-220-7465</a></span>
            </Col>
          </Row>
        </Container>
      </Header>
    )
  }
}

LoanOptions.defaultProps = {
  prospect: {
    firstName: '',
    lastName: ''
  },
  loanOptions: {
  }
}

const mapStateToProps = (state) => {
  return {
    prospect: state.questionnaire.prospect,
    loanOptions: state.questionnaire.loanOptions,
    appInfo: state.commonInfo.appInfo
  }
}

const mapDispatchToProps = {
  getLoanOptions: questionnaireActions.getLoanOptions,
  getAppInfo: commonActions.getAppInfo
}

export default connect(mapStateToProps, mapDispatchToProps)(LoanOptions);

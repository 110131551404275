import { questionnaireService } from 'services/questionnaireService';
import { questionnaireConstants } from 'constants/questionnaire';
import { history } from 'helpers/history';

const submitZipCode = (zipcode) => {
  const success = data => {
    return {
      type: questionnaireConstants.SUBMIT_ZIPCODE_SUCCESS,
      payload: data.zipcode
    };
  };

  const failure = error => {
    return {
      type: questionnaireConstants.SUBMIT_ZIPCODE_FAILURE,
      payload: error
    };
  };

  return dispatch => {
    questionnaireService.submitZipCode(zipcode).then(
      data => {
        if (data.success) {
          dispatch(success(data));
          history.push('/property-type');
        } else {
          history.push('/sorry-page');
        }
      },
      error => {
        dispatch(failure(error));
        history.push('/sorry-page');
      }
    );
  };
};

const getLoanOptions = (mortgageUid) => {
  const success = data => {
    return {
      type: questionnaireConstants.GET_LOAN_OPTIONS_SUCCESS,
      payload: data
    };
  };

  const failure = error => {
    return {
      type: questionnaireConstants.GET_LOAN_OPTIONS_FAILURE,
      payload: error
    };
  };

  return dispatch => {
    questionnaireService.getLoanOptions(mortgageUid).then(
      data => {
        dispatch(success(data));
      },
      error => {
        dispatch(failure(error));
      }
    );
  };
};

const submitMortgageOption = (mortgageOption) => {
  const success = data => {
    return {
      type: questionnaireConstants.SUBMIT_MORTGAGE_OPTION_SUCCESS,
      payload: data
    };
  };

  const failure = error => {
    return {
      type: questionnaireConstants.SUBMIT_MORTGAGE_OPTION_FAILURE,
      payload: error
    };
  };

  const resetState = {
    type: questionnaireConstants.RESET_STATE
  }

  return dispatch => {
    questionnaireService.submitMortgageOption(mortgageOption).then(
      data => {
        dispatch(success(data));
        dispatch(resetState);
      },
      error => {
        dispatch(failure(error));
      }
    );
  };
}

const setRefinanceType = (refinanceTypeId) => {
  const success = id => {
    return {
      type: questionnaireConstants.SET_REFINANCE_TYPE,
      payload: id
    };
  };

  return dispatch => {
    dispatch(success(refinanceTypeId));
  };
}

const setPropertyType = (propertyTypeId) => {
  const success = id => {
    return {
      type: questionnaireConstants.SET_PROPERTY_TYPE,
      payload: id
    };
  };

  return dispatch => {
    dispatch(success(propertyTypeId));
  };
}

const setPropertyUse = (propertyUseId) => {
  const success = id => {
    return {
      type: questionnaireConstants.SET_PROPERTY_USE,
      payload: id
    };
  };

  return dispatch => {
    dispatch(success(propertyUseId));
  };
}

const setPropertyValue = (minChooseValue, maxChooseValue) => {
  const success = (min, max) => {
    return {
      type: questionnaireConstants.SET_PROPERTY_VALUE,
      payload: {
        min: min,
        max: max
      }
    };
  };

  return dispatch => {
    dispatch(success(minChooseValue, maxChooseValue));
  };
}

const setFirstMortgageRate = (percent) => {
  const success = (value) => {
    return {
      type: questionnaireConstants.SET_FIRST_MORTGAGE_RATE,
      payload: value
    };
  };

  return dispatch => {
    dispatch(success(percent));
  };
}

const setFirstMortgageRemaining = (minValue, maxValue) => {
  const success = (min, max) => {
    return {
      type: questionnaireConstants.SET_FIRST_MORTGAGE_REMAINING,
      payload: {
        minRemaining: min,
        maxRemaining: max
      }
    };
  };

  return dispatch => {
    dispatch(success(minValue, maxValue));
  };
}

const setAdditionalCash = (cash) => {
  const success = (value) => {
    return {
      type: questionnaireConstants.SET_ADDITIONAL_CASH,
      payload: value
    };
  };

  return dispatch => {
    dispatch(success(cash));
  };
}

const setAverageIncome = (minValue, maxValue) => {
  const success = (min, max) => {
    return {
      type: questionnaireConstants.SET_AVERAGE_INCOME,
      payload: {
        minMonthlyIncome: min,
        maxMonthlyIncome: max
      }
    };
  };

  return dispatch => {
    dispatch(success(minValue, maxValue));
  };
}

const setEstimateYourCredit = (minScore, maxScore) => {
  const success = (minScore, maxScore) => {
    return {
      type: questionnaireConstants.SET_ESTIMATE_YOUR_CREDIT,
      payload: {
        minScore,
        maxScore
      }
    };
  };

  return dispatch => {
    dispatch(success(minScore, maxScore));
  };
}

const setProspectName = (firstName, lastName) => {
  const success = (firstName, lastName) => {
    return {
      type: questionnaireConstants.SET_PROSPECT_NAME,
      payload: {
        firstName,
        lastName
      }
    };
  };

  return dispatch => {
    dispatch(success(firstName, lastName));
  };
}

const setProspectEmail = (email) => {
  const success = (email) => {
    return {
      type: questionnaireConstants.SET_PROSPECT_EMAIL,
      payload: {
        email
      }
    };
  };

  return dispatch => {
    dispatch(success(email));
  };
}

export const questionnaireActions = {
  submitZipCode,
  submitMortgageOption,
  setRefinanceType,
  setPropertyUse,
  setPropertyType,
  setPropertyValue,
  setFirstMortgageRate,
  setFirstMortgageRemaining,
  setAdditionalCash,
  setAverageIncome,
  setEstimateYourCredit,
  getLoanOptions,
  setProspectName,
  setProspectEmail
};

import React, { Component } from 'react';
import './PropertyValue.scss';
import 'rc-slider/assets/index.css';
import { Container, Row, Col } from 'react-bootstrap';
import Slider from 'rc-slider';
import { connect } from 'react-redux';
import { questionnaireActions } from 'actions/questionnaire';
import { history } from 'helpers/history';
import { commonActions } from 'actions/common'

class PropertyValue extends Component {
  constructor(props) {
    super(props);

    this.state = {
      minChooseValue: 80001,
      maxChooseValue: 100000,
      editMode: false
    };
  }

  goBack = () => {
    history.goBack();
  }

  onSliderChange = (value) => {
    let minChooseValue = 80000 + value * 20000 + 1;
    let maxChooseValue = minChooseValue + 20000 - 1;

    this.setState({
      minChooseValue,
      maxChooseValue,
      editMode: true
    });
  }

  submitPropertyValue = () => {
    this.props.setPropertyValue(this.state.minChooseValue, this.state.maxChooseValue);
    history.push('/first-mortgage-remaining');
  }

  numberWithCommas(number) {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  render() {
    return(
      <Container className="property-value-page">
        <Row className="text-row">
          <Col xs={12} md={{span: 8, offset: 2}}>
            <h3 className="color-blue question-title">Please estimate the value of the property</h3>
          </Col>
        </Row>

        <Row className="range-row">
          <Col xs={12} md={{span: 6, offset: 3}}>
            {
              this.props.propertyMinValue && !this.state.editMode ?
              <span className="text">
                ${this.numberWithCommas(this.props.propertyMinValue)} to ${this.numberWithCommas(this.props.propertyMinValue)}
              </span>
              :
              <span className="text">
                ${this.numberWithCommas(this.state.minChooseValue)} to ${this.numberWithCommas(this.state.maxChooseValue)}
              </span>
            }
          </Col>
        </Row>

        <Row className="range-item">
          <Col xs={12} md={{span: 6, offset: 3}}>
            <Slider
              className='slider-custom'
              min={0}
              max={95}
              onChange={this.onSliderChange}
              defaultValue={this.props.propertyMinValue ? (this.props.propertyMinValue - 80001)/20000 : 0}
            />
          </Col>

          <Col xs={6} md={{span: 3, offset: 3}} className="min">
            <span>$80k</span>
          </Col>

          <Col xs={6} md={3} className="max">
            <span>$2M+</span>
          </Col>
        </Row>

        <Row className="btn-row mt-5">
          <Col xs={12} md={{span: 6, offset: 3}}>
            <button className="next-btn" onClick={this.submitPropertyValue}><span>Next </span></button>
          </Col>
        </Row>
      </Container>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    propertyTypeId: state.questionnaire.propertyTypeId,
    propertyMinValue: state.questionnaire.propertyMinValue,
    propertyMaxValue: state.questionnaire.propertyMaxValue,
    currentStep: state.currentStep
  }
}

const mapDispatchToProps = {
  setPropertyValue: questionnaireActions.setPropertyValue,
  setCurrentStep: commonActions.setCurrentStep
}


export default connect(mapStateToProps, mapDispatchToProps)(PropertyValue);

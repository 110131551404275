import { fetcher } from 'helpers/fetcher';
import { handleResponse } from './handleResponse';

const getAppInfo = () => {
  const companyName = '1nmc';
  const apiUrl = `${process.env.REACT_APP_API_URL}/companies/${companyName}`;

  const requestOptions = {
    method: 'GET'
  }

  return fetcher(apiUrl, requestOptions)
    .then(handleResponse)
    .then(data => {
      return data;
    })
}

export const commonService  = {
  getAppInfo
}
const updateOptions = (options) => {
  const update = { ...options };

  update.headers = {
    ...update.headers,
    'mode': 'cors',
    'Content-Type': options['Content-Type'] || 'application/json'
  }

  return update;
}

export const fetcher = (url, options) => {
  return fetch(url, updateOptions(options));
}

import { fetcher } from 'helpers/fetcher';
import { handleResponse } from './handleResponse';

const submitZipCode = (zipcode) => {
  const apiUrl = `${process.env.REACT_APP_API_URL}/questionnaires/verify_zipcode/${zipcode}`;

  const requestOptions = {
    method: 'GET'
  }

  return fetcher(apiUrl, requestOptions)
    .then(handleResponse)
    .then(data => {
      return data;
    })
}

const submitMortgageOption = (user) => {
  const apiUrl = `${process.env.REACT_APP_API_URL}/questionnaires/handle_mortgage_data`;

  const requestOptions = {
    method: 'POST',
    body: JSON.stringify(user)
  }

  return fetcher(apiUrl, requestOptions)
    .then(handleResponse)
    .then(data => {
      return data;
    })
}

const getLoanOptions = (mortgageUid) => {
  const apiUrl = `${process.env.REACT_APP_API_URL}/analytic_mortgages/get_loan_options/${mortgageUid}`;

  const requestOptions = {
    method: 'GET'
  }

  return fetcher(apiUrl, requestOptions)
    .then(handleResponse)
    .then(data => {
      return data;
    })
}

export const questionnaireService = {
  submitZipCode,
  submitMortgageOption,
  getLoanOptions
}

import { commonService } from 'services/commonService';
import { commonConstants } from 'constants/common';

const getAppInfo = (callback) => {
  const success = data => {
    callback(data.info.company.logo_url)
    return {
      type: commonConstants.GET_APP_INFO_SUCCESS,
      payload: data.info
    };
  };

  const failure = error => {
    return {
      type: commonConstants.GET_APP_INFO_FAILUE,
      payload: error
    };
  };

  return dispatch => {
    commonService.getAppInfo().then(
      data => {
        if (data.success) {
          dispatch(success(data));
        } else {
          console.log("==== Something went wrong ====")
        }
      },
      error => {
        dispatch(failure(error));
        console.log(`ERROR: ${error}`)
      }
    );
  };
}

const setCurrentStep = (step) => {
  return {
    type: commonConstants.SET_PROGRESS_BAR_INFO,
    payload:  {
      step: step
    }
  }
}

const setCurrentMaxStep = (maxStep) => {
  return {
    type: commonConstants.SET_MAXIMUM_STEP,
    payload: {
      maxStep: maxStep
    }
  }
}

export const commonActions = {
  getAppInfo,
  setCurrentStep,
  setCurrentMaxStep
};

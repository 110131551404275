import React, { Component } from 'react';
import './FirstMortgageRate.scss';
import 'rc-slider/assets/index.css';
import { Container, Row, Col, Button } from 'react-bootstrap';
import Slider from 'rc-slider';
import { connect } from 'react-redux';
import { questionnaireActions } from 'actions/questionnaire';
import { history } from 'helpers/history';

class FirstMortgageRate extends Component {
  constructor(props) {
    super(props);

    this.state = {
      percent: 0,
      editMode: false
    };
  }

  goBack = () => {
    history.goBack();
  }

  onSliderChange = (value) => {
    let percent = value * 0.25;

    this.setState({
      percent,
      editMode: true
    });
  }

  submitFirstMortgageRate = () => {
    this.props.setFirstMortgageRate(this.state.percent);
    history.push('/first-mortgage-remaining');
  }

  render() {
    return(
      <Container className="first-mortgage-rate-page">
        <Row className="text-row">
          <Col xs={12} md={{span: 6, offset: 3}}>
            <span>Please Estimate Credit Score</span>
          </Col>
        </Row>

        <Row className="range-row">
          <Col xs={12} md={{span: 6, offset: 3}}>
            {
              this.props.firstMortgageRate && !this.state.editMode ?
              <span className="text">
                {this.props.firstMortgageRate}%
              </span>
              :
              <span className="text">
                {this.state.percent}%
              </span>
            }
          </Col>
        </Row>

        <Row className="range-item">
          <Col xs={12} md={{span: 6, offset: 3}}>
            <Slider
              className='slider-custom'
              min={0}
              max={48}
              onChange={this.onSliderChange}
              defaultValue={this.props.firstMortgageRate ? this.props.firstMortgageRate/0.25 : 0}
            />
          </Col>

          <Col xs={6} md={{span: 3, offset: 3}} className="min">
            <span>0%</span>
          </Col>

          <Col xs={6} md={3} className="max">
            <span>12%</span>
          </Col>
        </Row>

        <Row className="btn-row">
          <Col xs={12} md={{span: 6, offset: 3}}>
            <Button className="btn" size="lg" block onClick={this.submitFirstMortgageRate}>
              <div className="continue"><span className="text">Continue</span></div>
            </Button>{' '}
          </Col>
        </Row>
      </Container>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    propertyTypeId: state.questionnaire.propertyTypeId,
    propertyMinValue: state.questionnaire.propertyMinValue,
    propertyMaxValue: state.questionnaire.propertyMaxValue,
    firstMortgageRate: state.questionnaire.firstMortgageRate
  }
}

const mapDispatchToProps = {
  setFirstMortgageRate: questionnaireActions.setFirstMortgageRate
}


export default connect(mapStateToProps, mapDispatchToProps)(FirstMortgageRate);

import { commonConstants } from 'constants/common';

const initialState = {};

export const commonInfo = (state = initialState, action) => {
  switch (action.type) {
    case commonConstants.GET_APP_INFO_SUCCESS:
      return {...state, appInfo: action.payload};
    case commonConstants.GET_APP_INFO_FAILUE:
      return {...state};
    default:
      return state;
  }
}

export const currentStep = (state = initialState, action) => {
  switch (action.type) {
    case commonConstants.SET_PROGRESS_BAR_INFO:
      return action.payload.step
    default:
      return state
  }
}

export const currentMaxStep = (state = initialState, action) => {
  switch (action.type) {
    case commonConstants.SET_MAXIMUM_STEP:
      return action.payload.maxStep
    default:
      return state
  }
}

import React, { Component } from 'react';
import './SorryPage.scss';
import { history } from 'helpers/history';
import { Container, Col, Row, Button, Image } from 'react-bootstrap';
import SorryFace from 'images/sorry-face.png';

class SorryPage extends Component {

  handleClose = () => {
    history.push('/');
  }

  render() {
    return(
      <Container className="sorry-page">
        <Row className="icon-row">
          <Col xs={12} md={{span: 6, offset: 3}}>
            <Image className="icon" src={SorryFace} rounded />
          </Col>
        </Row>

        <Row className="sorry-text">
          <Col xs={12} md={{span: 6, offset: 3}}>
            <span>We are sorry but we don't lend in that zipcode</span>
          </Col>
        </Row>

        <Row className="close-btn">
          <Col xs={12} md={{span: 6, offset: 3}}>
            <Button variant="secondary" size="lg" block onClick={this.handleClose}>
              Close
            </Button>{' '}
          </Col>
        </Row>
      </Container>
    )
  }
}

export default SorryPage;

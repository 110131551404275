import React, { Component } from 'react';
import './EstimateYourCredit.scss';
import 'rc-slider/assets/index.css';
import { Container, Row, Col } from 'react-bootstrap';
import Slider from 'rc-slider';
import { connect } from 'react-redux';
import { questionnaireActions } from 'actions/questionnaire';
import { history } from 'helpers/history';
import { commonActions } from 'actions/common'

class EstimateYourCredit extends Component {
  constructor(props) {
    super(props);

    this.state = {
      minScore: 0,
      maxScore: 0,
      editMode: false
    };
  }

  goBack = () => {
    history.goBack();
  }

  onSliderChange = (value) => {
    let minScore = 580;
    let maxScore = value;
    minScore = maxScore - 19;

    this.setState({
      minScore,
      maxScore,
      editMode: true
    });
  }

  submitEstimateYourCredit = () => {
    this.props.setEstimateYourCredit(this.state.minScore, this.state.maxScore);
    history.push('/prospect-name');
  }

  renderCurrentResult = (minScore, maxScore) => {
    if (maxScore <= 580 || minScore === 0) {
      return(
        <span>&lt; 580</span>
      );
    }

    if (maxScore > 760) {
      return(
        <span>&gt; 760</span>
      );
    }

    return(
      <span>{minScore} - {maxScore}</span>
    );
  }

  render() {
    return(
      <Container className="estimate-credit-score-page">
        <Row className="text-row">
          <Col xs={12} md={{span: 8, offset: 2}}>
            <h3 className="color-blue question-title">Please Estimate Your Credit Score</h3>
          </Col>
        </Row>

        <Row className="range-row">
          <Col xs={12} md={{span: 6, offset: 3}}>
            {
              this.props.minEstimateCreditScore && !this.state.editMode ?
              <span className="text">
                {this.renderCurrentResult(this.props.minEstimateCreditScore, this.props.maxEstimateCreditScore)}
              </span>
              :
              <span className="text">
                {this.renderCurrentResult(this.state.minScore, this.state.maxScore)}
              </span>
            }
          </Col>
        </Row>

        <Row className="range-item">
          <Col xs={12} md={{span: 6, offset: 3}}>
            <Slider
              className='slider-custom'
              min={580}
              max={780}
              step={20}
              onChange={this.onSliderChange}
              defaultValue={this.props.minEstimateCreditScore ? ((this.props.minEstimateCreditScore - 580) / 9) : 0}
            />
          </Col>

          <Col xs={6} md={{span: 3, offset: 3}} className="min">
            <span>&lt;580</span>
          </Col>

          <Col xs={6} md={3} className="max">
            <span>&gt;760</span>
          </Col>
        </Row>

        <Row className="btn-row">
          <Col xs={12} md={{span: 6, offset: 3}}>
            <button className="next-btn" onClick={this.submitEstimateYourCredit}><span>Next </span></button>
          </Col>
        </Row>
      </Container>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    propertyTypeId: state.questionnaire.propertyTypeId,
    propertyMinValue: state.questionnaire.propertyMinValue,
    propertyMaxValue: state.questionnaire.propertyMaxValue,
    minEstimateCreditScore: state.questionnaire.minEstimateCreditScore,
    maxEstimateCreditScore: state.questionnaire.maxEstimateCreditScore,
    currentStep: state.currentStep
  }
}

const mapDispatchToProps = {
  setEstimateYourCredit: questionnaireActions.setEstimateYourCredit,
  setCurrentStep: commonActions.setCurrentStep
}


export default connect(mapStateToProps, mapDispatchToProps)(EstimateYourCredit);

import React, { Component } from 'react';
import './FirstMortgageRemaining.scss';
import 'rc-slider/assets/index.css';
import { Container, Row, Col } from 'react-bootstrap';
import Slider from 'rc-slider';
import { connect } from 'react-redux';
import { questionnaireActions } from 'actions/questionnaire';
import { history } from 'helpers/history';
import { commonActions } from 'actions/common'

class FirstMortgageRemaining extends Component {
  constructor(props) {
    super(props);

    this.state = {
      minRemaining: this.props.firstMortgageMinRemaining || 100000,
      maxRemaining: this.props.firstMortgageMaxRemaining || 750000,
      editMode: false,
      minSlideInput: 10,
      maxSlideInput: 75
    };
  }


  goBack = () => {
    history.goBack();
  }

  onSliderChange = (value) => {
    let minRemaining;
    let maxRemaining;

    if (value <= 100) {
      minRemaining = value * 10000;
      maxRemaining = minRemaining + 10000;
    } else {
      minRemaining = 1000000 + (value - 100) * 50000;
      maxRemaining = minRemaining + 50000;
    }

    this.setState({
      minRemaining,
      maxRemaining,
      editMode: true
    });
  }

    validateLoanAmountFields = () => {
      let isValid = !isNaN(this.state.minRemaining) && !isNaN(this.state.maxRemaining) && (parseInt(this.state.minRemaining, 10) < parseInt(this.state.maxRemaining, 10))

      let continueBtnEle = document.getElementById("btn-continue");

      if(isValid) {
        continueBtnEle.disabled = false;
      } else {
        continueBtnEle.disabled = true;

      }

      return isValid;
    }

  onChangeMinimumLoanAmountField = () => {
    let minRemaining = document.getElementById("minimum-loan-amount").value;

    this.setState({
      minRemaining,
      editMode: true
    }, this.validateLoanAmountFields);
  }

  onChangeMaximumLoanAmountField = () => {
    let maxRemaining = document.getElementById("maximum-loan-amount").value;

    this.setState({
      maxRemaining,
      editMode: true
    }, this.validateLoanAmountFields);
  }

  skipAdditionalCashPage = () => {
    let { questionnaire, refinanceType }  = this.props
    const seletedRefinance= refinanceType.refinanceTypes.find(item => item.id === questionnaire.refinanceTypeId)
    return (seletedRefinance && seletedRefinance.refinance_type !== "take_cash_out")
  }

  submitFirstMortgageRemaining = () => {
    if(this.validateLoanAmountFields()) {
      this.props.setFirstMortgageRemaining(this.state.minRemaining, this.state.maxRemaining);
      if (this.skipAdditionalCashPage()) {
        history.push('/average-income')
      } else {
        this.props.setCurrentMaxStep(this.props.currentMaxStep + 1)
        history.push('/additional-cash')
      }
    }
  }

  numberWithCommas(number) {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  setMinSlideInput = (e) => {
    let { value } = e.currentTarget
    value = value/10
    this.setState({
      minSlideInput: value
    })

  }

  setMaxSlideInput = (e) => {
    let { value } = e.currentTarget
    value = value/10
    this.setState({
      maxSlideInput: value
    })
  }

  renderMinSlideInputNumber() {
    return this.state.minSlideInput * 10
  }

  renderMaxSlideInputNumber() {
    return this.state.maxSlideInput * 10
  }

  render() {
    return(
      <Container className="first-mortgage-remaining-page">
        <Row className="text-row">
          <Col xs={12} md={{span: 8, offset: 2}}>
            <h3 className="color-blue question-title">
              What is the outstanding loan amount on the property?             
            </h3>
          </Col>
        </Row>

        <Row className="range-row">
          <Col xs={12} md={{span: 6, offset: 3}}>
            {
              this.props.firstMortgageMinRemaining && !this.state.editMode ?
              <span className="text">
                ${this.numberWithCommas(this.props.firstMortgageMinRemaining)}
              </span>
              :
              <span className="text">
                ${this.numberWithCommas(this.state.minRemaining)}
              </span>
            }
          </Col>
        </Row>

       <Row className="range-item">
          <Col xs={12} md={{span: 6, offset: 3}}>
            <Slider
              className='slider-custom'
              min={this.state.minSlideInput}
              max={this.state.maxSlideInput}
              onChange={this.onSliderChange}
              defaultValue={this.props.firstMortgageMinRemaining ? this.props.firstMortgageMinRemaining/20000 : 0}
            />
          </Col>

          <Col xs={6} md={{span: 3, offset: 3}} className="min">
            <span>${this.renderMinSlideInputNumber()}k</span>
          </Col>

          <Col xs={6} md={3} className="max">
            <span>${this.renderMaxSlideInputNumber()}k</span>
          </Col>
        </Row>

        <Row className="btn-row">
          <Col xs={12} md={{span: 6, offset: 3}}>
            <button id="btn-continue" className="next-btn" onClick={this.submitFirstMortgageRemaining}><span>Next </span></button>
          </Col>
        </Row>
      </Container>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    firstMortgageMinRemaining: state.questionnaire.firstMortgageMinRemaining,
    firstMortgageMaxRemaining: state.questionnaire.firstMortgageMaxRemaining,
    questionnaire: state.questionnaire,
    refinanceType: state.refinanceType,
    currentStep: state.currentStep,
    currentMaxStep: state.currentMaxStep
  }
}

const mapDispatchToProps = {
  setFirstMortgageRemaining: questionnaireActions.setFirstMortgageRemaining,
  setCurrentStep: commonActions.setCurrentStep,
  setCurrentMaxStep: commonActions.setCurrentMaxStep
}

export default connect(mapStateToProps, mapDispatchToProps)(FirstMortgageRemaining);
